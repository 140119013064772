import React from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../contexts/user-context/user-context';
import { hasPermission } from '../../helpers/functions';
import { PERMISSON_TYPES } from '../../helpers/constant';
import { AiFillEdit } from 'react-icons/ai';

const HomeVenueCard = ({ venue }) => {
  const { permissions = [], setPostModalState } = React.useContext(UserContext);
  const [formattedDescription, setFormattedDescription] = React.useState(null);

  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions));
    }
  }, [permissions]);

  React.useEffect(() => {
    if (typeof venue?.content !== 'string') {
      setFormattedDescription('');
    }
    // TO HANDLE OLD STRING DATA
    else {
      setFormattedDescription(venue?.content);
    }
  }, []);

  return (
    <div className="my-8 border px-8 py-8">
      <h2 className="title-header flex items-center">
        {venue?.subject}
        {allowUpdate && (
          <span
            className="flex cursor-pointer items-center pl-2 text-sm capitalize"
            onClick={() =>
              setPostModalState({
                show: true,
                data: venue,
              })
            }
          >
            <AiFillEdit />
            <span>Edit</span>
          </span>
        )}
      </h2>
      {formattedDescription && (
        <div
          dangerouslySetInnerHTML={{ __html: formattedDescription }}
          className="w-full md:w-2/3"
        />
      )}
    </div>
  );
};

HomeVenueCard.propTypes = {
  venue: PropTypes.shape({
    subject: PropTypes.string,
    content: PropTypes.string,
  }),
};

export default HomeVenueCard;
