import React from 'react';
import './App.css';
import 'react-image-lightbox/style.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './pages/home';
import NewsPage from './pages/news';
import ContactPage from './pages/contact';
import FixturePage from './pages/fixtures';
import GalleryPage from './pages/gallery';
import LoginPage from './pages/login';
import MembershipPage from './pages/membership';
import UserContextProvider from './contexts/user-context/user-context-provider';
import GeneralRoute from './components/common/general-route';
import ProtectedRoute from './components/common/protected-route';
import AdminDashboardPage from './pages/admin';
import ForgotPasswordPage from './pages/forgot-password';
import ResetPasswordPage from './pages/reset-password';
import UpdatePasswordPage from './pages/update-password';

const App = () => {
  return (
    <UserContextProvider>
      <BrowserRouter>
        <Routes>
          {/* Start of Admin routes */}
          <Route
            path="/admin"
            element={
              <ProtectedRoute>
                <AdminDashboardPage />
              </ProtectedRoute>
            }
          />
          {/* End of Admin routes */}
          <Route
            path="/contact"
            element={
              <GeneralRoute>
                <ContactPage />
              </GeneralRoute>
            }
          />
          <Route
            path="/fixtures"
            element={
              <GeneralRoute>
                <FixturePage />
              </GeneralRoute>
            }
          />
          <Route
            path="/gallery"
            element={
              <GeneralRoute>
                <GalleryPage />
              </GeneralRoute>
            }
          />
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/membership"
            element={
              <GeneralRoute>
                <MembershipPage />
              </GeneralRoute>
            }
          />
          <Route
            path="/news"
            element={
              <GeneralRoute>
                <NewsPage />
              </GeneralRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/update-password" element={<UpdatePasswordPage />} />
          <Route path="/" element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default App;
