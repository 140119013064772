import React from 'react';
import PropTypes from 'prop-types';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
const PasswordInput = ({ register, name }) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);
  return (
    <div className="relative flex w-full items-center border">
      <input
        {...register(name, {
          required: true,
          minLength: 8,
        })}
        className="w-full border-none p-3"
        type={passwordVisible ? 'text' : 'password'}
        autoComplete="off"
      />
      <p
        onClick={() => setPasswordVisible((previous) => !previous)}
        className="absolute right-1 cursor-pointer text-lg text-gray-600"
      >
        {passwordVisible ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
      </p>
    </div>
  );
};

PasswordInput.propTypes = {
  register: PropTypes.func,
  name: PropTypes.string,
};

export default PasswordInput;
