import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../components/common/spinner';
import TitleDivider from '../components/common/title-divider';
import YearlyGalleryGrid from '../components/gallery/yearly-grid';
import APIRequest from '../helpers/api-request';

const GalleryPage = () => {
  const [state, setState] = useState({
    name: 'loading',
    data: [],
  });

  useEffect(() => {
    fetchGalleryData();
  }, []);

  const fetchGalleryData = async () => {
    const result = await APIRequest('GALLERIES', 'GET', {});
    if (!result || !result.ok) {
      toast.warning(result.message);
      return;
    }
    setState({
      name: 'results',
      data: result.data?.sort(
        (first, second) =>
          Number.parseInt(second.title) - Number.parseInt(first.title),
      ),
    });
  };

  return (
    <div className="mt-32 px-8 pt-8">
      {state.name === 'loading' && <Spinner />}
      {state.name === 'results' && (
        <>
          {state.data?.map((item) => (
            <div key={`Gallery-${item?.title}`} className="mb-16 px-8 pb-16">
              <div className="mb-4">
                <TitleDivider
                  title={item?.title}
                  headerClasses="text-gray-700"
                />
              </div>
              <YearlyGalleryGrid
                photos={item?.photos}
                year={item?.title}
                id={item?.id}
                isAdmin={false}
              />
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default GalleryPage;
