import React from 'react';
import PropTypes from 'prop-types';
import { AiFillEdit } from 'react-icons/ai';
import { hasPermission } from '../../helpers/functions';
import { PERMISSON_TYPES } from '../../helpers/constant';
import UserContext from '../../contexts/user-context/user-context';
import AboutUsImage from '../../assets/about-us.jpeg';

const HomeAboutUs = ({ aboutUs }) => {
  const { permissions = [], setPostModalState } = React.useContext(UserContext);
  const [formattedDescription, setFormattedDescription] = React.useState(null);

  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions));
    }
  }, [permissions]);

  React.useEffect(() => {
    if (typeof aboutUs?.content !== 'string') {
      setFormattedDescription('');
    }
    // TO HANDLE OLD STRING DATA
    else {
      setFormattedDescription(aboutUs?.content);
    }
  }, []);

  return (
    <div className="my-16 grid grid-cols-1 gap-8 md:grid-cols-2">
      <div>
        <div className="flex w-full justify-between">
          <h2 className="title-header flex items-center">
            {aboutUs?.subject}
            {allowUpdate && (
              <span
                className="flex cursor-pointer items-center pl-2 text-sm capitalize"
                onClick={() =>
                  setPostModalState({
                    show: true,
                    data: aboutUs,
                  })
                }
              >
                <AiFillEdit />
                <span>Edit</span>
              </span>
            )}
          </h2>
        </div>
        {formattedDescription && (
          <div dangerouslySetInnerHTML={{ __html: formattedDescription }} />
        )}
      </div>
      <div className="mx-auto">
        <img
          src={AboutUsImage}
          className="mx-auto h-auto w-full max-w-[600px] bg-gray-400 object-cover object-top brightness-75"
          alt="Random Picsum"
        />
      </div>
    </div>
  );
};

HomeAboutUs.propTypes = {
  aboutUs: PropTypes.shape({
    subject: PropTypes.string,
    content: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    imageUrl: PropTypes.string,
  }),
};

export default HomeAboutUs;
