import React from 'react';
import PropTypes from 'prop-types';

const Spinner = ({ additionalClasses = 'justify-center' }) => {
  return (
    <div className={`mt-12 flex ${additionalClasses}`}>
      <div className="h-12 w-12 animate-spin rounded-full border-y-8 border-solid border-sky-500 border-t-transparent shadow-md"></div>
    </div>
  );
};

Spinner.propTypes = {
  additionalClasses: PropTypes.string,
};

export default Spinner;
