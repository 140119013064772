import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import UserContext from '../../contexts/user-context/user-context';
import { Navigate } from 'react-router-dom';
import { PATHS } from '../../helpers/constant';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ children }) => {
  const { token } = useContext(UserContext);
  if (!token) {
    toast.warning('Please login first.');
    return <Navigate to={PATHS.CONTACT} replace={true} />;
  }
  return <>{children}</>;
};

ProtectedRoute.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ProtectedRoute;
