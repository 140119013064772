import PropTypes from 'prop-types';
import React from 'react';

const SingleNewsCard = ({ title, date, url }) => {
  return (
    <div className="w-full mx-auto mb-8 bg-transparent border">
      <div className="px-4">
        <h5 className="mt-6 font-bold">{title}</h5>
        <p className="mb-4 text-gray-500 ">{date}</p>
        <div className="pb-4">
          <a
            href={url}
            className="font-semibold text-primary hover:border-b-2 hover:border-b-primary"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

SingleNewsCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default SingleNewsCard;
