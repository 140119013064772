import React from 'react';
import PropTypes from 'prop-types';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ImFacebook2 } from 'react-icons/im';
import { BsInstagram } from 'react-icons/bs';
import Footer from '../components/common/footer';
import Navbar from '../components/navbar';

const MainLayout = ({ children }) => {
  return (
    <div className="relative overflow-x-hidden pb-12">
      <Navbar />
      <div className="mx-auto min-h-[90vh] max-w-[1920px]">{children}</div>
      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={6500}
        closeOnClick
        closeButton
        draggable
        pauseOnHover
      />

      <div className="fixed bottom-4 right-4 z-[100] flex items-center gap-4 border bg-white">
        <a
          href="https://www.facebook.com/westwimbledon"
          target="_blank"
          rel="noreferrer"
          className="mx-2 ml-6 text-lg text-primary"
        >
          <ImFacebook2 />
        </a>
        <a
          href="https://www.instagram.com/westwimbledonbc/"
          target="_blank"
          rel="noreferrer"
          className="mx-2 text-lg text-primary"
        >
          <BsInstagram />
        </a>
        <a
          href="/contact"
          target="_blank"
          rel="noreferrer"
          className="mr-6 border-b-2 border-b-white p-2 font-medium text-primary hover:border-b-primary"
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.element.isRequired,
};

export default MainLayout;
