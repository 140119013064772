import dayjs from 'dayjs';
import React from 'react';
import { toast } from 'react-toastify';
import APIRequest from '../../helpers/api-request';
import SingleNewsCard from '../common/news-card-single';
import wwbcLogo from '../../assets/wwbc-logo.png';
import { AiOutlineArrowRight } from 'react-icons/ai';

const formatNewsData = (data) => {
  let upcomingNews = [];
  let generalNews = [];

  for (const newsItem of data) {
    if (dayjs(newsItem.publishedDate).isAfter(dayjs())) {
      upcomingNews.push(newsItem);
    } else {
      generalNews.push(newsItem);
    }
  }

  return [...upcomingNews, ...generalNews]
    ?.sort(
      (first, second) =>
        dayjs(second.publishedDate).unix() - dayjs(first.publishedDate).unix(),
    )
    ?.slice(0, 3);
};

const HomeNews = () => {
  const [newsState, setNewsState] = React.useState({
    name: 'loading',
    data: {},
  });

  React.useEffect(() => {
    fetchNewsData();
  }, []);

  const fetchNewsData = async () => {
    const result = await APIRequest('NEWS', 'GET', {});
    if (!result || !result.ok) {
      toast.warning(result.message);
      return;
    }

    const formattedNews = formatNewsData(result.data);

    setNewsState((previous) => ({
      ...previous,
      name: 'results',
      data: formattedNews,
    }));
  };

  return (
    <div>
      <h2 className="title-header">News</h2>
      <div className="mt-8 grid grid-cols-1 gap-8 md:grid-cols-3 md:gap-[128px]">
        {newsState?.name === 'results' &&
          newsState.data?.map((newsItem) => (
            <SingleNewsCard
              key={newsItem?.id}
              image={wwbcLogo}
              title={newsItem?.subject}
              date={dayjs(newsItem?.publishedDate).format('ddd D MM, YYYY')}
              url="/news"
            />
          ))}
      </div>
      <a
        className="ml-auto flex w-fit cursor-pointer items-center justify-end font-bold hover:text-primary hover:underline"
        href="/news"
      >
        See More
        <span className="pl-2">
          <AiOutlineArrowRight />
        </span>
      </a>
    </div>
  );
};

export default HomeNews;
