import React from 'react';
import PropTypes from 'prop-types';
import { MdAddAPhoto } from 'react-icons/md';

const TitleDivider = ({ title, headerClasses, openAddPhotosModal }) => {
  if (title === 'Favorites') {
    return <></>;
  }
  return (
    <div className="flex items-center text-start">
      <p className={`dashboardDivider mb-0 font-bold ${headerClasses}`}>
        <span>{title}</span>
      </p>
      {openAddPhotosModal && (
        <>
          <span className="px-1 pr-2 text-gray-400">|</span>

          <p
            className="flex cursor-pointer items-center text-gray-500 hover:text-black"
            onClick={openAddPhotosModal}
          >
            <MdAddAPhoto className="mr-1" />
            Add Photos
          </p>
        </>
      )}
    </div>
  );
};

TitleDivider.propTypes = {
  title: PropTypes.string,
  headerClasses: PropTypes.string,
  openAddPhotosModal: PropTypes.func,
};

export default TitleDivider;
