import {
  CalendarIcon,
  ChartBarIcon,
  DocumentIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  UsersIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Dialog, Transition } from '@headlessui/react';
import { PATHS, PERMISSON_TYPES } from '../../helpers/constant';

import AdminClubDocumentsSection from '../../components/admin/club-documents';
import AdminClubSection from '../../components/admin/clubs';
import AdminFixtureSection from '../../components/admin/fixtures';
import AdminGallerySection from '../../components/admin/gallery';
import AdminGameTypesSection from '../../components/admin/game-types';
import AdminNewsSection from '../../components/admin/news';
import AdminPostsSection from '../../components/admin/posts';
import AdminUsersSection from '../../components/admin/users';
import React from 'react';
import { SlLogout } from 'react-icons/sl';
import Spinner from '../../components/common/spinner';
import { TfiGallery } from 'react-icons/tfi';
import { TfiMenu } from 'react-icons/tfi';
import { ToastContainer } from 'react-toastify';
import UserContext from '../../contexts/user-context/user-context';
import { useNavigate } from 'react-router-dom';
import wwbcLogo from '../../assets/wwbc-logo.png';

const NAVIGATIONS = [
  {
    name: 'Members',
    icon: UsersIcon,
    permission: PERMISSON_TYPES.MEMBERS_READ,
  },
  {
    name: 'News',
    icon: CalendarIcon,
    permission: PERMISSON_TYPES.NEWS_READ,
  },
  {
    name: 'Posts',
    icon: InboxIcon,
    permission: PERMISSON_TYPES.POST_READ,
  },
  {
    name: 'Gallery',
    icon: TfiGallery,
    permission: PERMISSON_TYPES.GALLERY_READ,
  },
  {
    name: 'Fixtures',
    icon: ChartBarIcon,
    permission: PERMISSON_TYPES.FIXTURE_READ,
  },
  {
    name: 'Game Types',
    icon: FolderIcon,
    permission: PERMISSON_TYPES.GAME_TYPES_READ,
  },
  { name: 'Clubs', icon: HomeIcon, permission: PERMISSON_TYPES.CLUB_READ },
  {
    name: 'Club Documents',
    icon: DocumentIcon,
    permission: PERMISSON_TYPES.CLUB_DOCUMENTS_READ,
  },
];

const AdminDashboardPage = () => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [activeSection, setActiveSection] = React.useState('Members');
  const [navigation, setNavigation] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const { setUser, setToken, permissions, passwordChange, user } =
    React.useContext(UserContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (passwordChange === 'true') {
      navigate(PATHS.UPDATE_PASSWORD);
    }
    if (permissions?.length > 0) {
      const allowedRoutes = NAVIGATIONS.filter((item) =>
        permissions.includes(item.permission),
      );

      setNavigation(allowedRoutes);
      setActiveSection(allowedRoutes[0]?.name);
      setIsLoading(false);
    }
  }, [permissions, passwordChange]);

  const logoutHandle = () => {
    setToken();
    setUser();
    navigate(PATHS.HOME);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="mx-auto">
          {/* Mobile View */}
          <Transition.Root show={sidebarOpen} as={React.Fragment}>
            <Dialog
              as="div"
              className="relative z-40 md:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={React.Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={React.Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-gray-700 pt-5 pb-4">
                    <Transition.Child
                      as={React.Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex flex-shrink-0 items-center px-4">
                      <a href={PATHS.HOME} className="cursor-pointer">
                        <img
                          src={wwbcLogo}
                          alt="logo"
                          className="h-auto w-[60px]  md:w-[80px]"
                        />
                      </a>
                    </div>
                    <div className="mt-5 h-0 flex-1 overflow-y-auto">
                      <nav className="space-y-1 px-2">
                        {navigation?.map((item) => (
                          <div
                            key={item.name}
                            onClick={() => setActiveSection(item?.name)}
                            className={`group flex cursor-pointer items-center rounded-md px-2 py-2 text-base font-medium ${
                              activeSection === item?.name
                                ? 'bg-gray-800 text-white'
                                : 'text-gray-100 hover:bg-gray-600'
                            }`}
                          >
                            <item.icon
                              className="mr-4 h-6 w-6 flex-shrink-0 text-gray-300"
                              aria-hidden="true"
                            />
                            {item.name}
                          </div>
                        ))}

                        {['Admin', 'ProductOwner'].includes(user?.role) && (
                          <div
                            onClick={() => setActiveSection('Club Documents')}
                            className={`group flex cursor-pointer items-center rounded-md px-2 py-2 text-[14px] font-medium ${
                              activeSection === 'Documents'
                                ? 'bg-gray-800 text-white'
                                : 'text-gray-100 hover:bg-gray-600'
                            }`}
                          >
                            <DocumentIcon
                              className="mr-4 h-6 w-6 flex-shrink-0 text-gray-300"
                              aria-hidden="true"
                            />
                            Club Documents
                          </div>
                        )}
                        <div
                          onClick={logoutHandle}
                          className={
                            'group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium text-red-400'
                          }
                        >
                          <span className="mr-4 flex-shrink-0 text-xl ">
                            <SlLogout />
                          </span>
                          Logout
                        </div>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-grow flex-col overflow-y-auto bg-gray-700 pt-5">
              <div className="flex flex-shrink-0 items-center px-4">
                <a href={PATHS.HOME} className="cursor-pointer">
                  <img
                    src={wwbcLogo}
                    alt="logo"
                    className="h-auto w-[60px] md:w-[80px]"
                  />
                </a>
              </div>
              <div className="mt-5 flex flex-1 flex-col">
                <nav className="flex-1 space-y-1 px-2 pb-4">
                  {navigation?.map((item) => (
                    <div
                      key={item.name}
                      onClick={() => setActiveSection(item.name)}
                      className={`group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium ${
                        item.name === activeSection
                          ? 'bg-gray-800 text-white'
                          : 'text-gray-100 hover:bg-gray-600'
                      }`}
                    >
                      <item.icon
                        className="mr-3 h-6 w-6 flex-shrink-0 text-gray-300"
                        aria-hidden="true"
                      />
                      {item.name}
                    </div>
                  ))}
                  {navigation &&
                    ['Admin', 'ProductOwner'].includes(user?.role) && (
                      <div
                        onClick={() => setActiveSection('Club Documents')}
                        className={`group flex cursor-pointer items-center rounded-md px-2 py-2 text-[14px] font-medium ${
                          activeSection === 'Documents'
                            ? 'bg-gray-800 text-white'
                            : 'text-gray-100 hover:bg-gray-600'
                        }`}
                      >
                        <DocumentIcon
                          className="mr-4 h-6 w-6 flex-shrink-0 text-gray-300"
                          aria-hidden="true"
                        />
                        Club Documents
                      </div>
                    )}
                  <div
                    onClick={logoutHandle}
                    className={
                      'group flex cursor-pointer items-center rounded-md px-2 py-2 text-sm font-medium text-red-400'
                    }
                  >
                    <span className="mr-4 flex-shrink-0 text-xl ">
                      <SlLogout />
                    </span>
                    Logout
                  </div>
                </nav>
              </div>
            </div>
          </div>

          {/* Actual dashboard */}
          <div className="flex flex-1 flex-col md:pl-64">
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white shadow md:hidden">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500 "
                onClick={() => setSidebarOpen(true)}
              >
                <TfiMenu />
              </button>
              <a href={PATHS.HOME} className="cursor-pointer px-4">
                <img
                  src={wwbcLogo}
                  alt="logo"
                  className="h-auto w-[60px]  md:w-[80px]"
                />
              </a>
            </div>
            <main>
              <div className="py-6">
                <div className="mx-auto mb-4 max-w-7xl px-4 sm:px-6 md:px-8">
                  <h1 className="text-center text-2xl font-semibold text-gray-900">
                    {activeSection}
                  </h1>
                </div>
                <div className="mx-auto max-w-7xl px-8 ">
                  {activeSection === 'Members' && (
                    <AdminUsersSection permissions={permissions} />
                  )}
                  {activeSection === 'Clubs' && <AdminClubSection />}
                  {activeSection === 'Game Types' && <AdminGameTypesSection />}
                  {activeSection === 'News' && <AdminNewsSection />}
                  {activeSection === 'Gallery' && <AdminGallerySection />}
                  {activeSection === 'Posts' && <AdminPostsSection />}
                  {activeSection === 'Fixtures' && <AdminFixtureSection />}
                  {activeSection === 'Club Documents' && (
                    <AdminClubDocumentsSection />
                  )}
                </div>
              </div>
            </main>
          </div>
        </div>
      )}
      <ToastContainer
        position="top-right"
        autoClose={6500}
        closeOnClick
        closeButton
        draggable
        pauseOnHover
      />
    </>
  );
};

export default AdminDashboardPage;
