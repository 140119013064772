import GeneralRoute from '../components/common/general-route';
import HomeAboutUs from '../components/home/about-us';
import HomeCarousel from '../components/home/home-carousel';
import HomeContactUs from '../components/home/contact-us';
import HomeNews from '../components/home/home-news';
import HomeVenueCard from '../components/home/venue-card';
import React from 'react';
import UserContext from '../contexts/user-context/user-context';

// import HomeHighlightSection from '../components/home/highlight-section';

const formatData = (data) => {
  const aboutUs = data.find((item) => item?.subject === 'About Us');
  const venue = data.find((item) => item?.subject === 'Venue');
  const contactInfo = data.find((item) => item?.subject === 'Contact-Info');

  return { aboutUs, venue, contactInfo };
};

const HomePage = () => {
  const [state, setState] = React.useState({
    name: 'loading',
    data: {},
  });
  const { posts } = React.useContext(UserContext);

  React.useEffect(() => {
    if (posts) {
      fetchHomeDetails();
    }
  }, [posts]);

  const fetchHomeDetails = async () => {
    setState({
      name: 'results',
      data: formatData(posts),
    });
  };

  return (
    <div>
      <HomeCarousel />
      <GeneralRoute>
        <div className="w-1/9 mx-auto px-8">
          {state.name === 'results' && (
            <>
              <HomeAboutUs aboutUs={state?.data?.aboutUs} />
              <HomeVenueCard venue={state?.data?.venue} />
            </>
          )}
          {/* <HomeHighlightSection /> */}
          <HomeNews />
          <HomeContactUs contactInfo={state?.data?.contactInfo} />
        </div>
      </GeneralRoute>
    </div>
  );
};

export default HomePage;
