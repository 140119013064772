import dayjs from 'dayjs';
import React from 'react';
import { toast } from 'react-toastify';
import Spinner from '../components/common/spinner';
import APIRequest from '../helpers/api-request';
import Select from 'react-select';
import UserContext from '../contexts/user-context/user-context';
import { hasPermission } from '../helpers/functions';
import { PERMISSON_TYPES } from '../helpers/constant';
import { AiFillEdit } from 'react-icons/ai';
import CommonModal from '../components/common/modal';
import FixtureScoreEditModal from '../components/admin/fixture-score-edit-modal';

const formatFixtureData = (data) => {
  let out = [];
  let possibleYears = [];

  for (const item of data) {
    const fixtureObject = {
      id: item?.id,
      fixtureTime: item?.dateTime,
      rinks: item?.rinks,
      score: item?.ourScore,
      opponentScore: item?.opponentScore,
      opponentName: item?.opponent?.fullName,
      homeTeamName: item?.homeTeam?.fullName || 'WWBC',
      venue: item?.venue,
      eventName: item?.event?.name,
      year: dayjs(item?.dateTime).year(),
      interestedUserIDs: item?.interestedUserIDs,
      interestedUsers: item?.interestedUsers,
      isJoinable: item?.isJoinable
        ? dayjs(item?.dateTime).isAfter(dayjs())
        : false,
      notes: item?.notes ?? '',
    };
    if (!possibleYears.includes(fixtureObject.year)) {
      possibleYears.push(fixtureObject.year);
    }
    out.push(fixtureObject);
  }

  return {
    out,
    possibleYears,
  };
};

const FixturePage = () => {
  const { permissions = [], user } = React.useContext(UserContext);

  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.SCORE_UPDATE, permissions),
  );

  const [modalState, setModalState] = React.useState({
    show: false,
    data: {},
  });

  const [fixturesState, setFixturesState] = React.useState({
    name: 'loading',
    allData: [],
    dataToShow: [],
    eventsToShow: [],
  });
  const [filters, setFilters] = React.useState({
    year: dayjs().year(),
    eventName: 'All',
  });
  const [joinStatus, setJoinStatus] = React.useState('');

  React.useEffect(() => {
    fetchNewsData();
  }, []);

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.SCORE_UPDATE, permissions));
    }
  }, [permissions]);

  React.useEffect(() => {
    if (fixturesState.name === 'results') {
      calculateDataToShow(filters, fixturesState.allData);
    }
  }, [filters]);

  const fetchNewsData = async () => {
    const result = await APIRequest('FIXTURES', 'GET', {});
    if (!result || !result.ok) {
      toast.warning(result.message);
      return;
    }

    const { out, possibleYears } = formatFixtureData(result.data);

    setFixturesState((previous) => ({
      ...previous,
      allData: out,
      possibleYears,
    }));

    calculateDataToShow(filters, out);
  };

  const calculateDataToShow = (currentFilters, allData) => {
    setFixturesState((previous) => ({
      ...previous,
      name: 'loading',
    }));
    const sortedAllData = allData.sort(
      (first, second) =>
        dayjs(first.fixtureTime).unix() - dayjs(second.fixtureTime).unix(),
    );
    let filteredEvents = [];
    let eventsToShow = ['All'];
    let upcomingFixtureDate = false;
    for (const item of sortedAllData) {
      if (item.year === currentFilters.year) {
        if (!eventsToShow.includes(item.eventName)) {
          eventsToShow.push(item.eventName);
        }

        if (currentFilters.eventName !== 'All') {
          if (item.eventName === currentFilters.eventName) {
            let active = upcomingFixtureDate
              ? dayjs(upcomingFixtureDate).isSame(dayjs(item.fixtureTime))
              : dayjs().isBefore(dayjs(item.fixtureTime));
            if (active) {
              upcomingFixtureDate = item.fixtureTime;
            }
            filteredEvents.push({ ...item, active });
          }
        } else {
          let active = upcomingFixtureDate
            ? dayjs(upcomingFixtureDate).isSame(dayjs(item.fixtureTime))
            : dayjs().isBefore(dayjs(item.fixtureTime));
          if (active) {
            upcomingFixtureDate = item.fixtureTime;
          }
          filteredEvents.push({ ...item, active });
        }
      }
    }

    setFixturesState((previous) => ({
      ...previous,
      name: 'results',
      dataToShow: filteredEvents,
      eventsToShow,
    }));
  };

  const showJoinInterest = async (data) => {
    const isJoin = !data?.interestedUserIDs?.includes(user?.id);
    setJoinStatus('loading');
    let result = await APIRequest('FIXTURES', 'POST', {
      encrypt: true,
      addToUrl: `/${data.id}/request-play`,
      body: {
        play: isJoin,
      },
    });
    if (result?.ok) {
      toast.success(result?.data?.message);
      await fetchNewsData();
    } else {
      toast.warning(result?.message || 'Something went wrong.');
    }
    setJoinStatus('false');
  };

  return (
    <>
      <div className="mt-32 px-8 pt-8">
        {fixturesState.name === 'loading' && <Spinner />}
        {fixturesState.name === 'results' && (
          <div className="mx-auto mb-12 max-w-[800px]">
            <div className="relative flex flex-col flex-wrap items-center md:flex-row">
              <div className="mb-6 w-full md:w-fit">
                <label>Year</label>
                <Select
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="year"
                  value={{ label: filters?.year, value: filters?.year }}
                  options={fixturesState.possibleYears?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(selected) => {
                    if (!selected) {
                      setFilters((previous) => ({
                        ...previous,
                        year: dayjs().year(),
                      }));
                    } else {
                      setFilters((previous) => ({
                        ...previous,
                        year: selected.value,
                      }));
                    }
                  }}
                  className="min-w-[300px] max-w-[400px]"
                />
              </div>

              <div className="mb-6 w-full md:ml-4 md:w-fit">
                <label>Event Name</label>
                <Select
                  classNamePrefix="select"
                  isClearable={true}
                  isSearchable={true}
                  name="eventName"
                  value={{
                    label: filters?.eventName,
                    value: filters?.eventName,
                  }}
                  options={fixturesState.eventsToShow?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                  onChange={(selected) => {
                    if (!selected) {
                      setFilters((previous) => ({
                        ...previous,
                        eventName: 'All',
                      }));
                    } else {
                      setFilters((previous) => ({
                        ...previous,
                        eventName: selected.value,
                      }));
                    }
                  }}
                  className="min-w-[300px] max-w-[400px]"
                />
              </div>
            </div>
            {fixturesState.dataToShow?.map((fixture) => (
              <div key={fixture?.id} className="mb-16 md:mb-4">
                <div
                  className={` relative  border border-gray-300 p-4 ${
                    fixture.active ? 'border-primary bg-primary text-white' : ''
                  } `}
                >
                  <div key={`Fixture-${fixture?.id}`}>
                    <div className="flex flex-wrap items-center gap-2 md:flex-nowrap">
                      <div className="w-1/3 md:w-3/12">
                        {dayjs(fixture?.fixtureTime).format('ddd D MMM, YYYY')}
                        <br />
                        {dayjs(fixture.fixtureTime).format('hh:mm a')}
                      </div>
                      <div className="w-1/3 md:w-3/12">{fixture.eventName}</div>
                      <div className="w-1/3 font-bold md:w-2/12">
                        {fixture?.venue}
                        <br />
                        {fixture?.rinks}
                      </div>

                      {fixture.eventName === 'WWBC Event' ? (
                        <div className="w-full md:w-4/12 ">
                          <div className="my-2 flex items-center justify-between gap-4">
                            <p className="w-3/4">{fixture?.opponentName}</p>
                          </div>
                        </div>
                      ) : (
                        <div className="w-full md:w-4/12 ">
                          {fixture?.venue === 'Home' && (
                            <div className="my-2 flex items-center justify-between gap-4">
                              <p className="w-3/4">{fixture?.homeTeamName}</p>
                              <p className="w-1/4 font-medium">
                                {Number.parseInt(fixture?.score)
                                  ? fixture?.score
                                  : '-'}
                              </p>
                            </div>
                          )}

                          <div className="my-2 flex items-center justify-between gap-4">
                            <p className="w-3/4">{fixture?.opponentName}</p>
                            <p className="w-1/4 font-medium">
                              {fixture?.opponentScore ?? '-'}
                            </p>
                          </div>

                          {fixture?.venue === 'Away' && (
                            <div className="my-2 flex items-center justify-between gap-4">
                              <p className="w-3/4">{fixture?.homeTeamName}</p>
                              <p className="w-1/4 font-medium">
                                {Number.parseInt(fixture?.score)
                                  ? fixture?.score
                                  : '-'}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  {allowUpdate && (
                    <div
                      className="absolute top-0 left-full cursor-pointer pl-2 text-black"
                      onClick={() =>
                        setModalState({ show: true, data: fixture })
                      }
                    >
                      <AiFillEdit />
                    </div>
                  )}
                  {fixture?.isJoinable &&
                    user?.role &&
                    user?.role !== 'SocialMember' && (
                      <>
                        <button
                          className="absolute bottom-[-40px] right-0 ml-2 block cursor-pointer bg-black px-2 py-1 text-white disabled:cursor-default disabled:bg-gray-400 md:hidden"
                          onClick={() => showJoinInterest(fixture)}
                          disabled={joinStatus === 'loading'}
                        >
                          {fixture?.interestedUserIDs?.includes(user?.id)
                            ? 'Leave'
                            : 'Join'}
                        </button>
                        <button
                          className="absolute bottom-0 left-full ml-2 hidden cursor-pointer bg-black px-2 py-1 text-white disabled:cursor-default disabled:bg-gray-400 md:block"
                          onClick={() => showJoinInterest(fixture)}
                          disabled={joinStatus === 'loading'}
                        >
                          {fixture?.interestedUserIDs?.includes(user?.id)
                            ? 'Leave'
                            : 'Join'}
                        </button>
                      </>
                    )}
                </div>
                {fixture?.notes && (
                  <div className="border bg-gray-50 p-4">{fixture?.notes}</div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <CommonModal
        open={modalState?.show}
        setOpen={() => setModalState({ show: false, data: {} })}
        modalClasses="md:min-w-[400px]"
      >
        <FixtureScoreEditModal
          open={modalState?.show}
          data={modalState?.data}
        />
      </CommonModal>
    </>
  );
};

export default FixturePage;
