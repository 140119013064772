import React from 'react';
import PropTypes from 'prop-types';
import { LOCAL_STORAGE_KEYS } from '../../helpers/constant';
import useLocalStorage from '../../hooks/use-is-localstorage';
import UserContext from './user-context';
import APIRequest from '../../helpers/api-request';
import CommonModal from '../../components/common/modal';
import PostEditModal from '../../components/admin/post-edit-modal';

const UserContextProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage(LOCAL_STORAGE_KEYS.USER, {}, true);
  const [token, setToken] = useLocalStorage(LOCAL_STORAGE_KEYS.USER_TOKEN, '');
  const [passwordChange, setPasswordChange] = useLocalStorage(
    LOCAL_STORAGE_KEYS.USER_PASSWORD_CHANGE,
    '',
  );

  const [posts, setPosts] = React.useState();
  const [permissions, setPermissions] = React.useState();
  const [postModalState, setPostModalState] = React.useState({
    show: false,
    data: {},
  });

  React.useEffect(() => {
    getPosts();
  }, []);

  React.useEffect(() => {
    if (token) {
      getPermissions();
    }
  }, [token]);

  const getPosts = async () => {
    const result = await APIRequest('POSTS', 'GET', {});
    if (result && result.ok) {
      setPosts(result.data);
    }
  };

  const getPermissions = async () => {
    const result = await APIRequest('USERS', 'GET', {
      encrypt: true,
      addToUrl: '/me',
    });
    if (!result | !result.ok) {
      setUser(null);
      setToken(null);
    } else {
      setPermissions(result.data.permissions);
      setPasswordChange(result.data?.needsPasswordChange);
    }
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        setToken,
        token,
        posts,
        permissions,
        setPostModalState,
        getPosts,
        passwordChange,
      }}
    >
      {children}
      <CommonModal
        open={postModalState?.show ?? false}
        setOpen={() => setPostModalState({ show: false, data: {} })}
        modalClasses="md:min-w-[800px] min-w-full"
      >
        <PostEditModal
          open={postModalState?.show}
          setOpen={() => setPostModalState({ show: false, data: {} })}
          data={postModalState?.data}
        />
      </CommonModal>
    </UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UserContextProvider;
