const API_URLS = {
  USER_LOGIN: 'login',
  USER_REGISTER: 'register',
  USER_REGISTER_MANY: 'register-many',
  GALLERIES: 'galleries',

  NEWS: 'news',

  POSTS: 'posts',

  USERS: 'users',

  CLUBS: 'clubs',

  EVENTS: 'events',

  FIXTURES: 'fixtures',

  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
  UPDATE_PASSWORD: 'password',

  IMAGE_UPLOAD: 'image/upload',

  CONTACT: 'contact',

  CLUB_DOCUMENTS: 'club-documents',
};

export default API_URLS;
