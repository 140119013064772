import { getFormattedText, hasPermission } from '../helpers/functions';

import { AiFillEdit } from 'react-icons/ai';
import ContactForm from '../components/common/contact-form';
import { PERMISSON_TYPES } from '../helpers/constant';
import React from 'react';
import UserContext from '../contexts/user-context/user-context';

const ContactPage = () => {
  const {
    posts,
    setPostModalState,
    permissions = [],
  } = React.useContext(UserContext);
  const [state, setState] = React.useState({
    name: 'loading',
    data: {},
  });
  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions));
    }
  }, [permissions]);

  React.useEffect(() => {
    if (posts) {
      const contactUs = posts.find((item) => item.subject === 'Contact Us');
      const clubDetails = posts.find(
        (item) => item?.subject === 'Club Details',
      );
      const contactInfo = posts.find((item) => item.subject === 'Contact-Info');

      setState({
        name: 'results',
        data: {
          contactUs,
          clubDetails,
          contactInfo,
        },
      });
    }
  }, [posts]);

  return (
    <div className="mx-auto mt-32 px-8 pt-8">
      <p className="title-header">Contact Us</p>
      <div className="grid grid-cols-1 gap-12 md:grid-cols-2 ">
        <div>
          {state?.name === 'results' && (
            <div className="mx-auto mb-4 text-sm">
              <div
                dangerouslySetInnerHTML={{
                  __html: getFormattedText(state?.data?.contactUs?.content),
                }}
              ></div>
            </div>
          )}
          <ContactForm />
        </div>
        <div className="ml-0 md:ml-8">
          <div className="mb-4">
            {allowUpdate && (
              <span
                className="flex cursor-pointer items-center text-sm capitalize"
                onClick={() =>
                  setPostModalState({
                    show: true,
                    data: state?.data?.contactInfo,
                  })
                }
              >
                <AiFillEdit />
                <span>Edit</span>
              </span>
            )}
            {state?.name === 'results' && (
              <div className="terxt-sm mx-auto mb-4">
                <div
                  dangerouslySetInnerHTML={{
                    __html: getFormattedText(state?.data?.contactInfo?.content),
                  }}
                ></div>
              </div>
            )}
          </div>
          {allowUpdate && (
            <span
              className="flex cursor-pointer items-center text-sm capitalize"
              onClick={() =>
                setPostModalState({
                  show: true,
                  data: state?.data?.clubDetails,
                })
              }
            >
              <AiFillEdit />
              <span>Edit</span>
            </span>
          )}
          {state.name === 'results' && state?.data?.clubDetails?.content && (
            <>
              <div
                dangerouslySetInnerHTML={{
                  __html: getFormattedText(state?.data?.clubDetails?.content),
                }}
              ></div>
            </>
          )}
        </div>
      </div>
      <div className="position-relative mt-8">
        <iframe
          height="400"
          title="WWBC_LOCATION"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d622.1258824910481!2d-0.2344978!3d51.4121764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487608df7f34dcdb%3A0x85babff4a786c5b0!2sWest%20Wimbledon%20Bowling%20Club!5e0!3m2!1sen!2snp!4v1691594581536!5m2!1sen!2snp"
          className="w-full border-0"
          allowFullScreen="true "
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactPage;
