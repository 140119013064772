import 'react-quill/dist/quill.snow.css';

import {
  // AiFillDelete,
  AiFillEdit,
} from 'react-icons/ai';
import { PERMISSON_TYPES, QUILL_FORMATS } from '../../helpers/constant';
import {
  getFormattedText,
  hasPermission,
  parseImageURL,
} from '../../helpers/functions';

import APIRequest from '../../helpers/api-request';
import CommonModal from '../common/modal';
import React from 'react';
import ReactQuill from 'react-quill';
import Spinner from '../common/spinner';
import UserContext from '../../contexts/user-context/user-context';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';

const AdminPostsSection = () => {
  const { permissions = [] } = React.useContext(UserContext);
  const [state, setState] = React.useState({
    name: 'loading',
    data: [],
  });
  const [editorState, setEditorState] = React.useState('');
  const [contentModal, setContentModal] = React.useState({
    show: false,
    content: {},
  });
  const [open, setOpen] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [allowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );
  // const [allowDelete] = React.useState(
  //   hasPermission(PERMISSON_TYPES.POST_DELETE, permissions),
  // );
  const [allowCreate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_CREATE, permissions),
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm();

  React.useEffect(() => {
    fetchPosts();
  }, []);

  const reactQuillRef = React.useRef(null);

  const modules = React.useMemo(
    () => ({
      toolbar: {
        container: [
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link', 'image'],
          [{ color: [] }, { background: [] }, { align: [] }],
          ['clean'],
        ],
        handlers: {
          image: async () => {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            input.click();

            input.onchange = async () => {
              if (input.files && input.files.length > 0) {
                const item = input.files[0];
                const formData = new FormData();
                formData.append('image', item);
                toast.success('Uploading Image...');

                const result = await APIRequest('IMAGE_UPLOAD', 'POST', {
                  encrypt: true,
                  body: formData,
                  formData: true,
                });

                if (result && result.ok) {
                  const quill = reactQuillRef?.current?.getEditor();
                  const cursorPosition = quill.getSelection().index;
                  const link = parseImageURL(result.data.imagePath);

                  // max-width: 100%;
                  quill.setSelection(cursorPosition + 1);
                  quill.insertEmbed(cursorPosition, 'image', link);

                  quill.setSelection(cursorPosition + 1);
                } else {
                  toast.error('Failed to upload image.');
                }
              }
            };
          },
        },
      },
    }),
    [reactQuillRef],
  );

  const fetchPosts = async () => {
    const result = await APIRequest('POSTS', 'GET', { encrypt: true });
    if (result && result.ok) {
      setState({
        name: 'results',
        data: result.data,
      });
    } else {
      setState({
        name: 'invalid',
        message: result.message,
      });
    }
  };

  const handlePostsCall = async (data) => {
    if ((isEditMode && allowUpdate) || (!isEditMode && allowCreate)) {
      // continue
    }
    const content = editorState;
    let result = isEditMode
      ? await APIRequest('POSTS', 'PUT', {
          encrypt: true,
          addToUrl: `/${data.id}`,
          body: {
            ...data,
            content,
          },
        })
      : await APIRequest('POSTS', 'POST', {
          encrypt: true,
          body: {
            ...data,
            content,
          },
        });

    if (result && result.ok) {
      toast.success('Added new post successfully');
      reset();
      setOpen(false);
      fetchPosts();
    } else {
      toast.warning('Failed to add new post');
    }
  };

  // const handleDelete = async (id) => {
  //   if (allowDelete) {
  //     if (window.confirm('Are you sure you want to delete the post?')) {
  //       const result = await APIRequest('POSTS', 'DELETE', {
  //         encrypt: true,
  //         addToUrl: `/${id}`,
  //       });
  //       if (result && result.ok) {
  //         toast.success('Deleted post successfully');
  //         fetchPosts();
  //       } else {
  //         toast.warning('Failed to delete post');
  //       }
  //     }
  //   }
  // };

  const openModal = (data, isEdit = false) => {
    reset({
      ...data,
    });

    if (isEdit) {
      if (typeof data.content !== 'string') {
        setEditorState('');
      }
      // TO HANDLE OLD STRING DATA
      else {
        setEditorState(
          data.content.replaceAll('<p><br></p>', '<p><br></p><p><br></p>'),
        );
      }
    } else {
      setEditorState('');
    }
    setIsEditMode(isEdit);
    setOpen(true);
  };

  return (
    <>
      {state.name === 'loading' && <Spinner />}
      {state.name === 'invalid' && (
        <p className="text-center italic text-gray-500">{state?.message}</p>
      )}
      {state.name === 'results' && (
        <div>
          <div className="mt-8 flex flex-col">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="min-w-full py-2 align-middle md:px-6 lg:px-8">
                {allowCreate && (
                  <div className="mb-8 ml-auto text-end">
                    <button
                      className="primary-btn"
                      onClick={() => openModal({}, false)}
                    >
                      Add Post
                    </button>
                  </div>
                )}
                <div className="overflow-x-auto shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300 overflow-scroll">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900 "
                        >
                          Subject
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Content
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Updated By
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Updated At
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {state.data?.map((item) => (
                        <tr key={item?.id}>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {item?.subject}
                          </td>
                          <td
                            className="cursor-pointer whitespace-nowrap px-3 py-4 text-sm text-primary"
                            onClick={() =>
                              setContentModal({
                                show: true,
                                content: item?.content,
                                subject: item?.subject,
                              })
                            }
                          >
                            Show Content
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>
                              {item?.lastUpdater
                                ? `${item?.lastUpdater?.firstName} ${item?.lastUpdater?.lastName}`
                                : `${item?.creator?.firstName ?? ''} ${
                                    item?.creator?.lastName ?? ''
                                  }`}
                            </div>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            <div>
                              {dayjs(item?.updatedAt).format('YYYY-MM-DD')}
                            </div>
                          </td>
                          <td>
                            <div className="flex items-center self-center">
                              {allowUpdate && (
                                <span
                                  className="mx-2 cursor-pointer text-cyan-400 hover:text-cyan-700"
                                  onClick={() => openModal(item, true)}
                                >
                                  <AiFillEdit />
                                </span>
                              )}
                              {/* {allowDelete && (
                                <span
                                  className="mx-2 cursor-pointer text-red-300 hover:text-red-700"
                                  onClick={() => handleDelete(item.id)}
                                >
                                  <AiFillDelete />
                                </span>
                              )} */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <CommonModal
        open={open}
        setOpen={() => setOpen(false)}
        modalClasses="md:min-w-[800px] min-w-full"
      >
        <form onSubmit={handleSubmit(handlePostsCall)}>
          <h4 className="mb-4 text-center font-bold">
            {isEditMode ? 'Edit post' : 'Add post'}
          </h4>

          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Slug <span className="text-[10px] italic">Unique Identifier</span>
              {errors?.slug && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Slug must be at least 2 letters.)
                </span>
              )}
            </label>
            <input
              {...register('slug', { required: true, minLength: 2 })}
              className="w-full border p-3"
              type="text"
              disabled={true}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">
              Subject
              {errors?.subject && (
                <span className="pl-2 text-[12px] text-red-600">
                  (Subject must be at least 5 letters.)
                </span>
              )}
            </label>
            <input
              {...register('subject', { required: true, minLength: 5 })}
              className="w-full border p-3"
              type="text"
              onChange={(event) => {
                setValue(
                  'slug',
                  event.target.value
                    ?.trim()
                    ?.replaceAll(' ', '-')
                    .toLowerCase(),
                );
              }}
            />
          </div>

          <div className="mb-2">
            <label className="text-sm text-gray-500">Content</label>
            <ReactQuill
              theme="snow"
              value={editorState}
              onChange={setEditorState}
              modules={modules}
              ref={reactQuillRef}
              formats={QUILL_FORMATS}
              className="relative h-[600px] bg-white"
            />
          </div>

          <div className="mt-4 text-center">
            <input
              type="submit"
              className="primary-btn cursor-pointer p-2 px-8 "
              value={isEditMode ? 'Save post' : 'Add post'}
            />
          </div>
        </form>
      </CommonModal>

      <CommonModal
        open={contentModal.show}
        setOpen={() =>
          setContentModal({ show: false, content: {}, subject: '' })
        }
        modalClasses="md:min-w-[800px] min-w-full"
      >
        <h5 className="mb-6 text-center text-xl font-bold">
          {contentModal?.subject}
        </h5>
        {contentModal?.show && typeof contentModal.content === 'string' && (
          <div
            dangerouslySetInnerHTML={{
              __html: getFormattedText(contentModal.content),
            }}
          />
        )}
        {contentModal?.show && typeof contentModal.content !== 'string' && (
          <div>Empty Content</div>
        )}
      </CommonModal>
    </>
  );
};

export default AdminPostsSection;
