import React from 'react';
import UserContext from '../contexts/user-context/user-context';
import { PERMISSON_TYPES } from '../helpers/constant';
import { hasPermission } from '../helpers/functions';
import { AiFillEdit } from 'react-icons/ai';
import MembershipImage from '../assets/membership.jpeg';

const MembershipPage = () => {
  const [state, setState] = React.useState({
    name: 'loading',
    data: {},
  });
  const {
    posts,
    permissions = [],
    setPostModalState,
  } = React.useContext(UserContext);

  const [allowUpdate, setAllowUpdate] = React.useState(
    hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions),
  );

  React.useEffect(() => {
    if (permissions) {
      setAllowUpdate(hasPermission(PERMISSON_TYPES.POST_UPDATE, permissions));
    }
  }, [permissions]);

  React.useEffect(() => {
    if (posts) {
      fetchMembershipDetails();
    }
  }, [posts]);

  const fetchMembershipDetails = async () => {
    const memberShipData = posts?.find(
      (item) => item?.slug === 'what-members-say',
    );
    const membership = posts?.find((item) => item?.slug === 'membership');
    setState({
      name: 'results',
      data: { memberShipData, membership },
    });
  };

  return (
    <div className="mx-auto mt-32 px-8 pt-8">
      <div className="grid grid-cols-1 gap-8  align-middle md:grid-cols-2">
        <div>
          <h2 className="title-header flex items-center">
            <span className="self-center  text-xl font-bold">
              {state?.data?.membership?.subject}
            </span>

            {allowUpdate && (
              <span
                className="flex cursor-pointer items-center pl-2 text-sm capitalize"
                onClick={() =>
                  setPostModalState({
                    show: true,
                    data: state?.data?.membership,
                  })
                }
              >
                <AiFillEdit />
                <span>Edit</span>
              </span>
            )}
          </h2>
          {/* <h4 className="mb-8 text-4xl font-medium">
            {state?.data?.membership?.title}
          </h4> */}
          <div className="mb-8 ">
            <div
              dangerouslySetInnerHTML={{
                __html: state?.data?.membership?.content,
              }}
            ></div>
          </div>
          <div>
            <div>
              <a href="/contact">
                <button className="primary-btn mr-4 mb-2 font-bold">
                  Join Us
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="self-center">
          <img
            src={MembershipImage}
            className="w-100 max-w-100 ml-auto h-auto bg-gray-400 object-cover object-center"
            alt="Membershpi"
          />
        </div>
      </div>
      <div>
        {state?.name === 'results' && (
          <div className="mt-16 grid grid-cols-1 gap-8 align-middle md:grid-cols-3">
            <h4 className="self-center  text-xl font-bold">
              {state?.data?.memberShipData?.subject}
              {allowUpdate && (
                <span
                  className="flex cursor-pointer items-center pl-2 text-sm capitalize"
                  onClick={() =>
                    setPostModalState({
                      show: true,
                      data: state?.data?.memberShipData,
                    })
                  }
                >
                  <AiFillEdit />
                  <span>Edit</span>
                </span>
              )}
            </h4>
            <div
              dangerouslySetInnerHTML={{
                __html: state?.data?.memberShipData?.content,
              }}
              className="col-span-2"
            ></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MembershipPage;
