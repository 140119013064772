import React from 'react';
import { useForm } from 'react-hook-form';
import { IoMdArrowBack } from 'react-icons/io';
import { PATHS } from '../helpers/constant';
import wwbcLogo from '../assets/wwbc-logo.png';
import APIRequest from '../helpers/api-request';
import { toast, ToastContainer } from 'react-toastify';
import PasswordInput from '../components/common/password-input';
import { randomPassword } from '../helpers/functions';

const UpdatePasswordPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [passwordChanged, setPasswordChanged] = React.useState(false);

  const onSubmit = async (values) => {
    if (values?.newPassword?.length !== values.confirmPassword?.length) {
      toast.warning('Password do not match.');
      return;
    }
    const result = await APIRequest('UPDATE_PASSWORD', 'POST', {
      encrypt: true,
      body: { ...values },
    });
    if (!result || !result.ok) {
      toast.warning(result?.message ?? 'Something went wrong.');
    } else {
      toast.success('Password successfully updated.');
      setPasswordChanged(true);
    }
  };

  return (
    <>
      <div className="relative mx-auto min-h-screen max-w-[500px]">
        <div className="mb-8 p-8">
          <a href={PATHS.HOME} className=" cursor-pointer">
            <img
              src={wwbcLogo}
              alt="logo"
              className=" mx-auto h-auto w-[80px]"
            />
          </a>
          <h1 className="mt-4 text-center text-3xl font-bold">
            WWBC Bowling Club
          </h1>
        </div>
        <div className="grid w-full grid-cols-1 gap-8 px-8">
          <div className="">
            {passwordChanged ? (
              <>
                <a href={PATHS.LOGIN} className="inline-flex items-center">
                  <span className="pr-2 text-xl">
                    <IoMdArrowBack />
                  </span>
                  Go to Login Page
                </a>
              </>
            ) : (
              <>
                <h4 className="mb-4 text-lg font-bold">Create new password</h4>

                <form onSubmit={handleSubmit(onSubmit)} className="mb-4">
                  <div className="mb-2 max-w-[400px]">
                    <label className="text-sm text-gray-500">
                      Old password
                      {errors?.oldPassword && (
                        <span className="pl-2 text-[12px] text-red-600">
                          (Password must be at least 8 letters.)
                        </span>
                      )}
                    </label>
                    <PasswordInput register={register} name="oldPassword" />
                  </div>

                  <div
                    onClick={() => {
                      const password = randomPassword();
                      setValue('newPassword', password);
                      setValue('confirmPassword', password);
                    }}
                    className="mt-8 cursor-pointer text-start text-[12px] underline"
                  >
                    Generate Random Password
                  </div>

                  <div className="mb-2 max-w-[400px]">
                    <label className="text-sm text-gray-500">
                      New password
                      {errors?.newPassword && (
                        <span className="pl-2 text-[12px] text-red-600">
                          (Password must be at least 8 letters.)
                        </span>
                      )}
                    </label>
                    <PasswordInput register={register} name="newPassword" />
                  </div>

                  <div className="mb-2 max-w-[400px]">
                    <label className="text-sm text-gray-500">
                      Confirm password
                      {errors?.confirmPassword && (
                        <span className="pl-2 text-[12px] text-red-600">
                          (Password must be at least 8 letters.)
                        </span>
                      )}
                    </label>
                    <PasswordInput register={register} name="confirmPassword" />
                  </div>

                  <div className="mt-4">
                    <input
                      type="submit"
                      className="primary-btn cursor-pointer p-2 px-8 text-sm"
                      value="RESET PASSWORD"
                    />
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={6500}
        closeOnClick
        closeButton
        draggable
        pauseOnHover
      />
    </>
  );
};

export default UpdatePasswordPage;
