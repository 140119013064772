import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TfiMenu } from 'react-icons/tfi';
import { MdClose } from 'react-icons/md';
import { NAV_LINKS, PATHS } from '../../helpers/constant';
import wwbcLogo from '../../assets/wwbc-logo.png';
import UserContext from '../../contexts/user-context/user-context';

const Navbar = () => {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const [links, setLinks] = React.useState(NAV_LINKS);
  const { token } = React.useContext(UserContext);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleScroll = () => {
      const nav = document.querySelectorAll('.navbar');
      if (window.scrollY > 70) {
        if (nav && nav[0]) {
          nav[0].classList.add('bg-white', 'text-black');
        }
      } else {
        if (nav && nav[0]) {
          nav[0].classList.remove('bg-white', 'text-black');
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const nav = document.querySelectorAll('.navbar');

    if (isNavOpen) {
      nav[0].classList.add('bg-white', 'text-black');
    } else {
      if (window.scrollY <= 70) {
        nav[0].classList.remove('bg-white', 'text-black');
      }
    }
  }, [isNavOpen]);

  useEffect(() => {
    if (!token || token?.length <= 0) {
      setLinks(() => [...NAV_LINKS, { label: 'Login', url: PATHS.LOGIN }]);
    } else {
      setLinks(() => [
        ...NAV_LINKS,
        { label: 'ADMIN', url: PATHS.ADMIN_DASHBOARD },
      ]);
    }
  }, [pathname, token]);

  return (
    <nav className="navbar fixed top-0 z-[99] flex w-full flex-row items-center justify-between py-4  px-4 md:px-8">
      <div>
        <a href={PATHS.HOME} className="cursor-pointer">
          <img
            src={wwbcLogo}
            alt="logo"
            className="h-auto w-[60px] md:w-[80px]"
          />
        </a>
      </div>
      {/* Desktop View */}
      <div className="hidden md:block">
        {links.map((link) => (
          <a
            href={link.url}
            className={`mx-4 p-2 uppercase  ${
              pathname === link.url
                ? 'bg-black text-white'
                : 'hover:border-b-2 hover:border-b-black'
            }`}
            key={link.url}
          >
            {link.label}
          </a>
        ))}
      </div>

      {/* Mobile Nav */}
      <div className="block md:hidden">
        <div
          className="border border-gray-400 p-2"
          onClick={() => setIsNavOpen((previous) => !previous)}
        >
          {isNavOpen ? <MdClose /> : <TfiMenu />}
        </div>
      </div>
      {isNavOpen && (
        <div className="z-1 absolute left-0 right-0 top-[80px]  bottom-0 h-screen bg-white pt-8 text-center md:hidden">
          {links.map((link) => (
            <div key={link.url} className="my-4">
              <a
                href={link.url}
                className={`mx-4 p-2 uppercase ${
                  pathname === link.url ? 'bg-black text-white' : ''
                }`}
              >
                {link.label}
              </a>
            </div>
          ))}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
